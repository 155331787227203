import {
  Organisation,
  User,
  OrgRole,
  ProductRole,
  ProductKey,
} from './../types/index';
import {
  getCurrentUserByUserId,
  getCurrentUserOrgsByUserId,
  getOrgUsers,
} from '../api';
import { auth } from '../firebase-export';

export const getCurrentUserData = async (
  currentOrgSlug: string,
  product?: ProductKey
): Promise<{
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  role?: ProductRole;
  orgRole?: OrgRole;
  organisations: Organisation[];
} | null> => {
  const currentUser = auth().currentUser;
  if (!currentUser) return null;

  // Get basic user info
  const { data: userData = {} } = await getCurrentUserByUserId();

  // Get user role for the current org
  let userOrgRole: OrgRole | null = userData.orgRole || null;
  const userCurrentProductRole: ProductRole | null =
    userData.productRole[product as string] || null;

  // If somehow user has productRole but no orgRole
  // It's most likely due to system error, we will give this user a default orgRole
  if (userCurrentProductRole && !userOrgRole) {
    userOrgRole = 'user';
  }

  // Get user orgs
  // If product is undefined (e.g. otso-auth), get all orgs
  // Otherwise, get product based orgs (e.g. insight, data)
  const { data: userOrgs = [] } = await getCurrentUserOrgsByUserId();

  // Get current organisation users if a user has permissions
  let currentOrgUsers: User[] = [];
  if (
    ['admin', 'analyst'].indexOf(userCurrentProductRole || '') >= 0 ||
    (!product && userOrgRole === 'admin')
  ) {
    ({ data: currentOrgUsers = [] } = await getOrgUsers(currentOrgSlug));
  }

  return {
    ...userData,
    ...(userCurrentProductRole ? { role: userCurrentProductRole } : {}),
    ...(userOrgRole ? { orgRole: userOrgRole } : {}),
    organisations: userOrgs.map((userOrg: Organisation) => {
      if (userOrg.id === currentOrgSlug)
        return { ...userOrg, users: currentOrgUsers };
      return userOrg;
    }),
  };
};
