import React, { useState } from 'react';
import { Result, Button, Typography } from 'antd';

import { useAuth } from './AuthProvider';
import { AppLayout } from './AppLayout';
import { preset } from './styles';
import { logout } from './logout';

type Props = { children: React.ReactNode };

export const AccessBoundary: React.FC<Props> = ({ children }) => {
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  const { user, product, authHost } = useAuth();

  const onLogout = async () => {
    setIsLoggingOut(true);
    await logout();
  };

  const hasAccess: boolean = product ? !!user?.role : !!user?.orgRole;

  if (hasAccess) return <>{children}</>;

  return (
    <AppLayout navMenu={[]}>
      <div style={{ marginTop: preset.spacing(10) }}>
        <Result
          status="warning"
          title="Access Denied"
          subTitle={
            <Typography.Text type="secondary">
              {product ? (
                user?.orgRole === 'admin' ? (
                  <>
                    You do not have access to otso{' '}
                    {product.charAt(0).toUpperCase()}
                    {product.slice(1)}. Please confirm your role in{' '}
                    <Typography.Link href={authHost} target="_blank">
                      otso Admin
                    </Typography.Link>
                  </>
                ) : (
                  `You do not have access to otso ${product}. Please contact the organisation admin.`
                )
              ) : (
                'You do not have access to this organisation. Please switch to another one or contact support.'
              )}
            </Typography.Text>
          }
          extra={[
            <Button
              type="primary"
              key="logout"
              loading={isLoggingOut}
              onClick={onLogout}>
              Back to Login
            </Button>,
            <Button key="reload" onClick={() => window.location.reload()}>
              Reload
            </Button>,
          ]}
        />
      </div>
    </AppLayout>
  );
};
