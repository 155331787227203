import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import firebase from 'firebase/app';
import { parseUrl, stringifyUrl } from 'query-string';
import { message } from 'antd';

import { AuthLoading } from './components';
import { getAuthToken, switchOrganisation } from './api';
import { CustomClaims, User, Organisation, ProductKey } from './types';
import { auth, app } from './firebase-export';
import { getCurrentUserData } from './db';
import { logout } from './logout';

export type AuthProviderProps = {
  children?: React.ReactNode;
};

type AuthContextType = {
  authHost: string;
  product?: ProductKey;
  authed: boolean;
  user: User | null;
  firebaseUser: firebase.User | null;
  currentOrg: Organisation | null;
  currentOrgStorageRef: firebase.storage.Reference | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  refreshUser: () => Promise<void>;
};

const initialState: AuthContextType = {
  authHost: '',
  authed: false,
  user: null,
  firebaseUser: null,
  currentOrg: null,
  currentOrgStorageRef: null,
  setUser: () => {},
  refreshUser: async () => {},
};

const AuthContext = createContext<AuthContextType>(initialState);

export const useAuth = () => useContext(AuthContext);

export const initAuthProvider = (authHost: string, product?: ProductKey) => {
  initialState.authHost = authHost;
  initialState.product = product;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);

  const firebaseUserRef = useRef<firebase.User | null>(null);
  const currentOrgStorageRef = useRef<firebase.storage.Reference | null>(null);

  const refreshUser = useCallback(async (firebaseUser?: firebase.User) => {
    const { product } = initialState;
    const currentFirebaseUser = firebaseUser || auth().currentUser;
    if (currentFirebaseUser) {
      const { uid, displayName, email, photoURL } = currentFirebaseUser;
      // Get user custom claims
      const {
        claims: { currentOrg, isSuper, timezone },
      }: { claims: CustomClaims } = await currentFirebaseUser.getIdTokenResult(
        true
      );
      if (!currentOrg)
        return Promise.reject({ message: 'No current organisation found' });
      // Set Org Storage Ref
      const storage = app().storage(`gs://otso-data-${currentOrg}`);
      currentOrgStorageRef.current = storage.ref();
      // Get current user data
      const currentUserData = await getCurrentUserData(currentOrg, product);
      // Set user data
      const userData = currentUserData
        ? {
            // Current user data
            ...currentUserData,
            // Custom claims
            currentOrg,
            isSuper,
            timezone,
            // Firebase user data
            displayName,
            email,
            photoURL,
            id: uid,
          }
        : null;
      setUser(userData);
    } else {
      setUser(null);
      // Clear ref
      currentOrgStorageRef.current = null;
    }
    return Promise.resolve();
  }, []);

  useEffect(() => {
    const {
      query: { org: newOrg, customToken, ...resetQuery },
    } = parseUrl(window.location.href);

    const switchOrgIfNeeded = async (
      firebaseUser: firebase.User
    ): Promise<boolean> => {
      if (newOrg && typeof newOrg === 'string') {
        const { claims } = await firebaseUser.getIdTokenResult(true);
        if (claims.currentOrg !== newOrg) {
          try {
            await switchOrganisation(newOrg);
            await firebaseUser.getIdToken(true);
            const newURL = stringifyUrl({
              url: `${window.location.origin}${window.location.pathname}`,
              query: resetQuery,
            });
            window.location.replace(newURL);
            return true;
          } catch {
            return false;
          }
        }
      }
      return false;
    };

    const authListener = auth().onAuthStateChanged(async firebaseUser => {
      // Set Firebase User
      firebaseUserRef.current = firebaseUser;

      setIsLoading(true);

      if (firebaseUser) {
        // Check org switch
        const isSwitchingOrg = await switchOrgIfNeeded(firebaseUser);
        if (!isSwitchingOrg) {
          // Set user state
          try {
            await refreshUser(firebaseUser);
          } catch (error) {
            message.error((error as Error).message || 'Internal Server Error');
            await logout();
          } finally {
            setIsLoading(false);
          }
        }
      } else {
        if (customToken && typeof customToken === 'string') {
          // Sign in with customToken if found
          try {
            await auth().signInWithCustomToken(customToken);
          } catch {
            setIsLoading(false);
          }
        } else {
          // Clear user state
          setUser(null);
          // Clear ref
          currentOrgStorageRef.current = null;
          try {
            // Login with auth token if found
            const authToken = await getAuthToken();
            await auth().signInWithCustomToken(authToken);
          } catch {
            setIsLoading(false);
          }
        }
      }
    });

    // Unsub auth listener
    return () => {
      authListener();
    };
  }, [refreshUser]);

  const currentOrg: Organisation | null = useMemo(() => {
    if (!user?.currentOrg || !user?.organisations) return null;
    const currentOrgData = user.organisations.find(
      org => org.id === user.currentOrg
    );
    return currentOrgData || null;
  }, [user]);

  if (isLoading) return <AuthLoading />;

  return (
    <AuthContext.Provider
      value={{
        authHost: initialState.authHost,
        product: initialState.product,
        authed: !!user,
        user,
        firebaseUser: firebaseUserRef.current,
        currentOrg,
        currentOrgStorageRef: currentOrgStorageRef.current,
        setUser,
        refreshUser,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
