import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';

export type NavMenuRoute =
  | { title: string; path: string }
  | { title: string; routes: { title: string; path: string }[] };

type Props = { routes: NavMenuRoute[]; className?: string };

export const NavMenu: React.FC<Props> = ({ routes, className }) => {
  const { pathname } = useLocation();

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[pathname]}
      className={className}
      style={{ borderBottomColor: 'transparent' }}>
      {routes.map(route =>
        'routes' in route ? (
          <Menu.SubMenu key={route.title} title={route.title}>
            {route.routes.map(childRoute => (
              <Menu.Item key={childRoute.path}>
                <Link to={childRoute.path}>{childRoute.title}</Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item key={route.path}>
            <Link to={route.path}>{route.title}</Link>
          </Menu.Item>
        )
      )}
    </Menu>
  );
};
